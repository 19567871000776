const userDefaults = JSON.parse(localStorage.getItem('user')) || null;

const state = {
    user: userDefaults,
    token: localStorage.getItem('access_token') || null,
    loader: false,
    loaderMensaje: "Cargando ...",
    efecto: false,
    efectoMensaje: "Cargando ...",


}

export default state