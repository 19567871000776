<template>
  <div class="auth-login" style="background: #fdfdfd; height: 100vh">
    <div
      class="nav"
      style="
        position: fixed;
        top: 0px;
        width: 100%;
        height: 60px;
        left: 0px;
        padding-left: 83px;
      "
    >
      
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-4">

         

          <div
            class="formulario-login d-flex"
            style="
              padding: 20px;
              justify-content: center;
              align-items: center;
              height: 100vh;
            "
          >

          
          
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    if (this.$store.state.user) {
      if (this.$store.state.user.type == "admin") {
        this.$router.push({ name: "AdminHome" });
      }
      if (this.$store.state.user.type == "user") {
        this.$router.push({ name: "Management" });
      }
    } else {
      localStorage.clear();
    }
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  width: 100%;
  height: 47px;
  bottom: 0;
  z-index: 1;
  background: #00000057;
  align-items: center;

  ul li {
    margin-left: 30px;
    font-size: 14px;
    color: white;
    font-weight: 600;
  }
}
</style>
