<template>
  <div class="web-main" style="padding-left: 59px; padding-right: 59px; text-align:left">
    <Nav />
    <Sidebar />
    <div class="body-container" >
      <div class="contenedor-body" style="padding: 14px; margin-top:80px; margin-left:220px;height:89vh
      ">
        <router-view />
      </div>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Footer from './footer.vue';
import Nav from "./nav.vue";
import Sidebar from './sidebar.vue';
import subMenu from "./subMenu.vue";
export default {
  components: {
    Nav,
    subMenu,
    Footer,
    Sidebar
},
  mounted() {
    console.log("cargando home");
  },
};
</script>
