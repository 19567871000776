<template>
  <div class="submenu">
    <div
      class="container-fluid d-flex flex-row"
      style="height: 30px
    align-items: center;
    position: absolute;
    margin-top: 32px;
    z-index: 20;
   "
    >
      <div
        class="disabled"
        style="
          margin-right: 13px;
          font-size: 14px;
          font-weight: 700;
          margin-left: 2%;
          color: rgb(202 202 202);
        "
      >
        Página inicial
      </div>
      <div class="disabled" style="color: white; margin-right: 13px; font-size: 14px">
        <i class="far fa-chevron-right"></i>
      </div>
      <div class="disabled" style="color: white; font-size: 14px; font-weight: 700">
        Cursos
      </div>
    </div>

    <div
      class="card header-buscador"
      style="
        background-image: url('img/ejemplo1.jpg');
        background-repeat: no-repeat;
        background-position: center -48px;
        background-size: 100%;
      "
    >
      <div
        class="sombra"
        style="
          position: absolute;
          width: 52%;
          height: 100%;
          background: rgb(0 0 0 / 53%);
          border-radius: 28px 0px 0px 28px;
          box-shadow: rgb(0 0 0 / 47%) 33px 0px 11px 0px;
        "
      ></div>

      <div class="textos">
        <h1 style="color: white">
          <strong>Comece seu curso</strong> <br />
          E inicie seu próprio negócio
        </h1>

        <br />

        <div class="form-group">
          <label for="">O que você está procurando?</label>
          <input
            type="text"
            class="form-control"
            v-model="buscador"
            v-on:keyup.enter="Buscador"
            placeholder="Busca de um curso..."
            style="
              height: 58px;
              border-radius: 12px;
              width: 80%;
              padding-left: 17px;
              border: none;
              background: #ffffffeb;
              margin-top: 10px;
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
 export default { 
  data(){
    return {
      categoria:null,
      buscador:null
    }
  },
  methods: {
    Buscador(){
      this.$fn.listarProductos(this.buscador, this.categoria);
    }
  }
 }
</script>
<style lang="scss">
.submenu {
  position: absolute;
  top: 60px;
  width: 100%;
  left: 0;

  .textos {
    position: absolute;
    padding: 10px;
    margin-top: 89px;
    padding-left: 22px;
    width: 50%;
    text-align: left;
    color: white;
  }

  .card {
    border-radius: 26px;
  }

  .header-buscador {
    height: 399px;
    background: #000000;
    width: 90%;
    margin-top: 24px !important;
    margin: auto;
    border: none !important;
  }
}
</style>
