<template>
  <div class="web-main">
    <Nav />
    <div class="body-container" >
      <div class="contenedor-body" style="padding: 14px;">
        <router-view />
      </div>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Footer from './footer.vue';
import Nav from "./nav.vue";
import subMenu from "./subMenu.vue";
export default {
  components: {
    Nav,
    subMenu,
    Footer
  },
  mounted() {
    console.log("cargando home");
  },
};
</script>

<style lang="scss" scoped>
  @media(max-width:768px){
    .web-main{
       text-align:left;
    }
    
  }
  @media(min-width:768px){
    .web-main{
      padding-left: 59px; padding-right: 59px; text-align:left;
    }
    
  }
  

  
</style>
