<template>
    <div class="sidebar">
         <div class="menu" style="     margin-top: 113px;
         padding: 24px 24px 24px 31px;">
             <ul>
                <li class="d-flex flex-row" @click="irRuta('AdminHome')"> <i class="fas fa-home" style="width:20%"></i> Home</li>
                <li class="d-flex flex-row" @click="irRuta('AdminUsers')"> <i class="fas fa-users" style="width:20%" ></i> Usuarios</li>
                <li class="d-flex flex-row" @click="irRuta('AdminDepartamentos')"> <i class="fas fa-building" style="width:20%"></i> Departamentos</li>
                <li class="d-flex flex-row" @click="irRuta('Configuracion')"> <i class="fas fa-cog" style="width:20%"></i> Configuración</li>
                <li class="d-flex flex-row" @click="irRuta('AdminApi')"> <i class="fas fa-window" style="width:20%"></i> API</li>
                <li class="d-flex flex-row" @click="logout()"> <i class="fas fa-sign-out-alt" style="width:20%"></i> Cerrar sesión</li>
               
             </ul>
         </div>
    </div>
</template>
<script>
   export default {
    methods:{
        irRuta(ruta){
            if(this.$route.name == ruta){
            }else {
              this.$router.push({name:ruta});
            }
           
        },
        logout() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href = "/";
        })
        .catch((error) => {
          toastr.error("Error al cerrar sesión");
        });
    },
    }
   }
</script>

<style lang="scss">


.sidebar {
    background: white;
    position: fixed;
    left: 0;
    top: 0;
    width: 266px;
    height: 100%;
    border-right: 1px solid #ddd;

    li {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    cursor:pointer
    }
}
</style>