<template>
  <div class="nav d-flex flex-row" style="justify-content: space-between">
    <div class="d-flex flex-row" style="align-items: center">
      <div class="logo">
        <img src="/img/logo.png" alt="" class="img-fluid" style="width: 170px" />
      </div>
      <div class="nameView lg">
        <div class="d-flex flex-column">
          <strong style="font-weight: 500; text-transform: uppercase; font-size: 15px"
            >Missing Calls V.1.0</strong
          >
          <small style="font-size: 12px"
            >Gestión de llamadas No Atendidas en sistemas Rapport</small
          >
        </div>
      </div>
    </div>

    <div class="menu-user d-flex flex-row" style="align-items: center">
      <div class="nombre-user lg">
        {{ $store.state.user.name }}
      </div>

      <div class="dropdown">
        <div
          class="avatar d-flex"
          type="button"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          {{ $store.state.user.name.substr(0, 1) }}
        </div>
        <div
          class="dropdown-menu"
          style="
            position: absolute;
            top: 0px;
            left: -80px !important;
            will-change: transform;
            padding: 13px;
            text-align: left;
            width: 185px;
          "
        >
          <div class="nombre-user-menu" style="padding-left: 10px">
            <small> <i class="fas fa-user mr-1"></i> {{ $store.state.user.name }}</small>
          </div>
          <a
            @click.prevent="logout()"
            class="dropdown-item"
            style="
              margin-top: 15px;
              text-align: left;
              padding: 0;
              padding-top: 10px;
              padding: 9px;
              color: #333;
            "
            href="#"
            ><i class="fas fa-sign-out-alt mr-1"></i> Cerrar Sesión</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store
        .dispatch("destroyToken")
        .then((response) => {
          window.location.href = "/";
        })
        .catch((error) => {
          toastr.error("Error al cerrar sesión");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .nav {
    padding-left: 23px !important;
    padding-right: 23px !important;
  }
}
.nav {
  z-index: 1000;
  align-items: center;
  padding-left: 79px;
  padding-right: 79px;
  position: fixed;
  background: white;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  height: 57px;
  border-bottom: 1px solid #e5e5e5;
  .nameView {
    margin-left: 81px;
    font-size: 16px;
    font-weight: 700;
    color: #767676;
  }

  .menu-user {
    margin-right: 10px;

    .dropdown-menu {
      position: absolute;
      will-change: transform;
      padding: 13px;
      text-align: left;
      width: 185px;
      top: 0px;
      left: -76px !important;
      transform: translate3d(-58px, 48px, 0px);
    }

    .nombre-user {
      margin-right: 10px;
      font-size: 13px;
      font-weight: 800;
      color: #6b6b6b;
      text-transform: uppercase;
    }

    .dropdown-item:active {
      background: rgb(228, 228, 228) !important;
    }

    .avatar {
      cursor: pointer;
      justify-content: center;
      align-items: center;
      height: 40px !important;
      width: 40px !important;
      color: #333;
      font-weight: bold;
      font-size: 20px;
    }
  }
}
</style>
