import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/template/adminWeb/index.vue'
import Admin from '../views/template/Admin/index.vue'
import Auth from '../views/template/auth/index.vue'
import store from '../store/store'


Vue.use(VueRouter)

const routes = [{

        path: '/management',
        component: HomeView,
        children: [{
            path: '',
            name: 'Management',
            component: () =>
                import ('../views/missing')
        }, {
            path: 'estadisticas',
            name: 'Estadisticas',
            component: () =>
                import ('../views/missing/Estadisticas')
        }],
        meta: {
            requiresAuth: true,
            roll: 'user',
        }
    },

    {
        path: '/admin',
        component: Admin,
        children: [{
                path: 'home',
                name: 'AdminHome',
                component: () =>
                    import ('../views/administrador/index.vue')
            }, {
                path: 'users',
                name: 'AdminUsers',
                component: () =>
                    import ('../views/administrador/user/index.vue')
            },
            {
                path: 'departamentos',
                name: 'AdminDepartamentos',
                component: () =>
                    import ('../views/administrador/departamentos/index.vue')
            },
            {
                path: 'settings',
                name: 'Configuracion',
                component: () =>
                    import ('../views/administrador/configuracion/index.vue')
            },
            {
                path: 'api',
                name: 'AdminApi',
                component: () =>
                    import ('../views/administrador/api/index.vue')
            }

        ],
        meta: {
            requiresAuth: true,
            roll: 'admin',
        }
    },

    {

        path: '/',
        component: Auth,
        children: [{
            path: '',
            name: 'Login',
            component: () =>
                import ('../views/template/auth/login')
        }]
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    console.log('soy navegacion router');
    console.log(to.matched.some(record => record.meta.requiresAuth));
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.loggedIn) {
            console.log('requiresAuth');
            next({ name: 'Login' })
        } else {
            console.log('Validacion ruta');
            var TypeUser = to.matched.some(record => record.meta.roll == store.state.user.type);
            console.log(store.state.user.type, 'userType');
            console.log(to.matched.some(record => record.meta.roll == store.state.user.type), 'meta');
            if (!TypeUser) {
                if (store.state.user.type == 'admin') {
                    console.log('Ruta incorrecta');
                    next({ name: 'AdminHome' })
                }
                if (store.state.user.type == 'user') {
                    console.log('Ruta incorrecta');
                    next({ name: 'Management' })
                }
            } else {
                console.log('Ruta correcta');
                next()
            }
        }
    } else {
        console.log('NO requiresAuth');
        next() // make sure to always call next()!
    }
})



export default router